import React from "react"
import { Link } from "gatsby"
import { FlexCenter, LinearTitle } from "../styles/elements.js"
import styled from "styled-components"

import "normalize.css" // Note this

import Seo from "../components/Seo"
import Layout from "../components/layout.js"
import Featured from "../components/Featured"
import Button from "../components/Button"

import { Paragraph } from "./../styles/elements.js"

const MLink = styled(Link)`
  font-family: var(--main-font);
  color: darkorange;
  text-decoration: underline;
  font-weight: 900;
`

const About = props => {
  return (
    <>
      <Layout>
        <Seo title="About CompoundYourself" />
        <Featured height="75vh">
          <FlexCenter>
            <LinearTitle>About Compound Yourself</LinearTitle>
          </FlexCenter>
          <Paragraph>
            Compound Yourself is a website that provides information about the
            many ways in which you can grow yourself online by learning.
          </Paragraph>

          <Paragraph>
            If you want to learn the secrets from the greats, you've come to the
            right place.
          </Paragraph>
          <Paragraph>
            It begins with a habit. It ends in inspiration. Want to get better,
            but don't have the time? Try to single out half an hour a day.
            Everyone has half an hour. Less TV. Maybe a commute.
          </Paragraph>

          <LinearTitle>Check out the trailer</LinearTitle>
          <Paragraph>
            Discover your passion in life through the greatest teachers.
          </Paragraph>
          <Paragraph>
            MasterClass.com is a subscription video site that showcases the top
            performers in different professions, ranging from elite athletes to
            top writers, actors and directors.
          </Paragraph>
          <Paragraph>
            Watch their trailer video <MLink to="/video">here</MLink>
          </Paragraph>

          <Paragraph>
            We are affiliated with different brands insofar that we refer
            traffic from our website to theirs. We aim to get our audience
            enthused about online learning from top performers. If you click
            through to any website from Compound Yourself you help to get our
            website get an income stream, as we may earn a commission from
            affiliate links.
          </Paragraph>
          <Paragraph>
            If you find any value on our website, please consider registering
            for any of our partners or to buy something through the links we
            mention, at no additional cost to you.
          </Paragraph>
          <Margin />
          <Button>
            <a
              href="https://www.masterclass.com"
              target="_blank"
              rel="noreferrer"
            >
              Register Here
            </a>
          </Button>
        </Featured>
      </Layout>
    </>
  )
}

const Margin = styled.div`
  margin: 6rem 0rem;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

export default About
